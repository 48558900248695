import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Divider,
  Container,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

interface SessionData {
  lastSoc: {
    value: number;
  };
  lastEnergyImport: {
    value: number;
  };
  lastPowerImport: {
    value: number;
  };
  lastSessionCost: {
    value: number;
  };
  chargeTimeRemaining: {
    value: number;
  };
  transactionSummary: {
    stopTimestamp: string | null;
    startingSoc: number;
    latestSoc: number;
  };
  startSoc?: {
    value: number;
  };
  targetSoc?: {
    value: number;
  };
}

interface ChargingSessionViewProps {
  sessionId: string;
}

const ProgressContainer = styled(Box)({
  position: 'relative',
  width: '300px',
  height: '300px',
});

const ChargingSessionView: React.FC<ChargingSessionViewProps> = ({ sessionId }) => {
  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const [isStoppingSession, setIsStoppingSession] = useState(false);

  useEffect(() => {
    const pollSession = async () => {
      try {
        const response = await fetch(`https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/ocpi/cpo/2.2.1/sessions/${sessionId}`);
        const data = await response.json();
        setSessionData(data);
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    };

    pollSession();
    const interval = setInterval(pollSession, 15000);
    return () => clearInterval(interval);
  }, [sessionId]);

  const handleStopSession = async () => {
    setIsStoppingSession(true);
    try {
      const response = await fetch(
        'https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/ocpi/cpo/2.2.1/commands/stop_session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ transactionId: sessionId }),
        }
      );
      
      if (!response.ok) {
        throw new Error('Failed to stop session');
      }
    } catch (error) {
      console.error('Error stopping session:', error);
    } finally {
      setIsStoppingSession(false);
    }
  };

  const formatTimeRemaining = (minutes: number) => {
    const hrs = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    return `${hrs} hrs ${mins} min`;
  };

  if (!sessionData) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );

  const startSoc = sessionData.transactionSummary?.startingSoc ?? 0;
  const currentSoc = sessionData.lastSoc.value;
  const targetSoc = sessionData.targetSoc?.value || 100;

  const lastSoc = sessionData.lastSoc.value ?? 0;
  const lastEnergyImport = sessionData.lastEnergyImport?.value ?? 0;
  const lastSessionCost = sessionData.lastSessionCost.value ?? 0;
  const lastPowerImport = sessionData.lastPowerImport?.value ?? 0;
  const chargeTimeRemaining = sessionData.chargeTimeRemaining?.value ?? 0;
  const pricePerKWh = lastSessionCost / lastEnergyImport;
  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        align="center"
        color="text.primary"
      >
        {sessionData.transactionSummary?.stopTimestamp 
          ? 'Charge Completed' 
          : 'Charge in Progress'
        }
      </Typography>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 4 }}>
        <ProgressContainer>
          <CircularProgress
            variant="determinate"
            value={100}
            size={300}
            thickness={8}
            sx={{ 
              position: 'absolute',
              color: 'grey.200'
            }}
          />
          
          <CircularProgress
            variant="determinate"
            value={targetSoc}
            size={300}
            thickness={8}
            sx={{
              position: 'absolute',
              color: 'grey.700',
              transform: 'rotate(-90deg)'
            }}
          />
          
          <CircularProgress
            variant="determinate"
            value={startSoc}
            size={300}
            thickness={8}
            sx={{
              position: 'absolute',
              color: 'warning.main',
              transform: 'rotate(-90deg)'
            }}
          />
          
          <CircularProgress
            variant="determinate"
            value={currentSoc}
            size={300}
            thickness={8}
            sx={{
              position: 'absolute',
              color: 'success.main',
              transform: 'rotate(-90deg)'
            }}
          />
          
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
          }}>
            <Typography variant="h3" component="div" sx={{ color: 'common.white' }}>
              {currentSoc}%
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {formatTimeRemaining(sessionData.chargeTimeRemaining.value)}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Time Remaining
            </Typography>
          </Box>
        </ProgressContainer>

        <Box sx={{ mt: 2, display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Typography variant="caption" color="text.secondary">
            Target SoC: {targetSoc}%
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Start SoC: {startSoc}%
          </Typography>
        </Box>
      </Box>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">Energy added</Typography>
            <Typography variant="h6">{lastEnergyImport.toFixed(2) || 0} kWh</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">Price per kWh</Typography>
            <Typography variant="h6">
              ${pricePerKWh.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">Current cost</Typography>
            <Typography variant="h6">${lastSessionCost.toFixed(2)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">Live Power</Typography>
            <Typography variant="h6">{lastPowerImport.toFixed(0)} kW</Typography>
          </Grid>
        </Grid>
      </Paper>

      {!sessionData.transactionSummary?.stopTimestamp && (
        <Button 
          variant="contained"
          color="error"
          fullWidth
          onClick={handleStopSession}
          disabled={isStoppingSession}
          sx={{ py: 1.5 }}
        >
          {isStoppingSession ? 'Stopping...' : 'Stop Charging'}
        </Button>
      )}
    </Container>
  );
};

export default ChargingSessionView; 