import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate, useParams } from 'react-router-dom';
import {
  Box,
  TextField,
  IconButton,
  Card,
  CardContent,
  Typography,
  Chip,
  Paper,
  List,
  ListItem,
  InputAdornment,
  Container,
  CircularProgress,
  ListItemButton,
  SwipeableDrawer,
  Divider,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import {
  MyLocation as MyLocationIcon,
  Search as SearchIcon,
  Bolt as BoltIcon,
  AttachMoney as AttachMoneyIcon,
  AccessTime as AccessTimeIcon,
  Favorite as FavoriteIcon,
  TuneRounded as FilterIcon,
  BoltRounded as DCFastIcon,
  AttachMoney as PriceIcon,
  CheckCircle as AvailableIcon
} from '@mui/icons-material';
import 'mapbox-gl/dist/mapbox-gl.css';
import ChargingStationDetail from './components/ChargingStationDetail';
import ChargingSessionView from './components/ChargingSessionView';
import { LocationResponse, Location } from './types';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from './theme';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || 'pk.eyJ1Ijoic3lub3BhbmRyZXciLCJhIjoiY2txZzFnd2JnMWF5MDJwbnpjcXNrYjBhbCJ9.4DS2cxbRoL4JcfMMq7SNuQ';

const DRAWER_SNAP_POINTS = {
  FULL: '90vh',
  HALF: '50vh',
  MIN: '140px'
};

const StationDetailWrapper: React.FC = () => {
  const { id } = useParams();
  const [station, setStation] = useState<Location | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStation = async () => {
      try {
        const response = await fetch(`https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/ocpi/cpo/2.2.1/locations/${id}`);
        const responseData = await response.json();
        setStation(responseData.data);
      } catch (error) {
        console.error('Error fetching station:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStation();
  }, [id]);

  if (loading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
  if (!station) return <Navigate to="/" replace />;

  return <ChargingStationDetail station={station} />;
};

const App: React.FC<{ setSelectedLocation: (location: Location | null) => void }> = ({ setSelectedLocation }) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const [userLocation, setUserLocation] = useState<[number, number]>([0, 0]);
  const [locationLoaded, setLocationLoaded] = useState(false);
  const [locations, setLocations] = useState<LocationResponse | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [filters, setFilters] = useState<string[]>([]);
  const [drawerHeight, setDrawerHeight] = useState(DRAWER_SNAP_POINTS.HALF);

  useEffect(() => {
    // Get user's location first
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords;
        setUserLocation([longitude, latitude]);
        setLocationLoaded(true);
      },
      (error) => {
        console.error('Error getting location:', error);
        // Default to New York if location access is denied
        setUserLocation([-74.5, 40]);
        setLocationLoaded(true);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  }, []);

  useEffect(() => {
    if (!mapContainer.current || !locationLoaded) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v11',
      center: userLocation,
      zoom: 14
    });

    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
      showUserHeading: true
    });

    map.current.addControl(geolocateControl, 'top-right');

    markerRef.current = new mapboxgl.Marker({
      color: darkTheme.palette.primary.main
    })
      .setLngLat(userLocation)
      .addTo(map.current);

    return () => {
      map.current?.remove();
    };
  }, [locationLoaded]);

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setLngLat(userLocation);
    }
  }, [userLocation]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch('https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/ocpi/cpo/2.2.1/locations');
        const data = await response.json();
        setLocations(data);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    if (!map.current || !locations) return;

    // Clear existing markers (if any)
    const markers: mapboxgl.Marker[] = [];

    locations.data.forEach((location) => {
      const marker = new mapboxgl.Marker({
        color: darkTheme.palette.primary.main,
        clickTolerance: 3
      })
        .setLngLat([Number(location.coordinates.longitude), Number(location.coordinates.latitude)])
        .addTo(map.current!);

      // Use Mapbox's click handler
      marker.getElement().style.cursor = 'pointer';
      marker.on('click', () => {
        console.log('clicked', location.id);
        navigate(`/locations/${location.id}`);
      });

      markers.push(marker);
    });

    // Cleanup markers on unmount
    return () => {
      markers.forEach(marker => marker.remove());
    };
  }, [locations, navigate]);

  const centerOnUser = () => {
    if (map.current) {
      map.current.easeTo({
        center: userLocation,
        zoom: 14,
        duration: 1000
      });
    }
  };

  const filteredLocations = locations?.data.filter(location =>
    location.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleLocationSelect = (locationId: string) => {
    setSearchQuery('');
    setShowResults(false);
    navigate(`/locations/${locationId}`);
  };

  const handleStationClick = (station: Location) => {
    setSelectedLocation(station);
    navigate(`/locations/${station.id}`);
  };

  const handleFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilters: string[],
  ) => {
    setFilters(newFilters);
  };

  return (
    <Box sx={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <Box 
        ref={mapContainer} 
        sx={{ 
          position: 'absolute', 
          top: 0, 
          bottom: 0, 
          left: 0, 
          right: 0,
          touchAction: 'pan-x pan-y pinch-zoom',
          pointerEvents: 'auto',
          '& .mapboxgl-map': {
            touchAction: 'pan-x pan-y pinch-zoom',
            pointerEvents: 'auto'
          },
          '& .mapboxgl-marker': {
            pointerEvents: 'auto',
            cursor: 'pointer'
          }
        }} 
      />
      
      <IconButton
        onClick={() => centerOnUser()}
        sx={{
          position: 'absolute',
          right: 20,
          top: 100,
          bgcolor: 'background.paper',
          '&:hover': { bgcolor: 'background.default' }
        }}
      >
        <MyLocationIcon />
      </IconButton>

      <SwipeableDrawer
        anchor="bottom"
        open={true}
        onOpen={() => {}}
        onClose={() => {}}
        disableSwipeToOpen={true}
        SwipeAreaProps={{
          onClick: (e: React.MouseEvent) => e.stopPropagation()
        }}
        ModalProps={{
          keepMounted: true,
          sx: { pointerEvents: 'none' }
        }}
        PaperProps={{
          sx: {
            pointerEvents: 'auto',
            height: drawerHeight,
            overflow: 'visible',
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            transition: 'height 0.3s ease-out',
            minHeight: DRAWER_SNAP_POINTS.MIN,
            bgcolor: 'background.default',
            backgroundImage: 'none'
          },
          onTouchStart: (e: React.TouchEvent) => {
            e.stopPropagation();
            const touch = e.touches[0];
            const startY = touch.clientY;
            
            const handleTouchMove = (e: TouchEvent) => {
              e.stopPropagation();
              const currentY = e.touches[0].clientY;
              const deltaY = currentY - startY;
              
              if (deltaY > 50) {
                if (drawerHeight === DRAWER_SNAP_POINTS.FULL) {
                  setDrawerHeight(DRAWER_SNAP_POINTS.HALF);
                } else if (drawerHeight === DRAWER_SNAP_POINTS.HALF) {
                  setDrawerHeight(DRAWER_SNAP_POINTS.MIN);
                }
              } else if (deltaY < -50) {
                if (drawerHeight === DRAWER_SNAP_POINTS.MIN) {
                  setDrawerHeight(DRAWER_SNAP_POINTS.HALF);
                } else if (drawerHeight === DRAWER_SNAP_POINTS.HALF) {
                  setDrawerHeight(DRAWER_SNAP_POINTS.FULL);
                }
              }
            };
            
            document.addEventListener('touchmove', handleTouchMove, { passive: false });
            document.addEventListener('touchend', () => {
              document.removeEventListener('touchmove', handleTouchMove);
            }, { once: true });
          }
        }}
      >
        <Box 
          sx={{ 
            position: 'relative',
            height: '20px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pt: 2,
            '&::after': {
              content: '""',
              position: 'absolute',
              width: '36px',
              height: '4px',
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              borderRadius: '2px',
            }
          }} 
        />
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 2, overflow: 'auto', whiteSpace: 'nowrap', px: 1 }}>
            <ToggleButtonGroup
              value={filters}
              onChange={handleFilterChange}
              aria-label="charging filters"
              sx={{ 
                gap: 1,
                '.MuiToggleButton-root': {
                  border: '1px solid rgba(255, 255, 255, 0.12)',
                  borderRadius: '20px !important',
                  px: 2,
                  py: 0.5,
                  textTransform: 'none',
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                  }
                }
              }}
            >
              <ToggleButton value="available" aria-label="available">
                <AvailableIcon sx={{ mr: 1, fontSize: 20 }} />
                Available
              </ToggleButton>
              <ToggleButton value="free" aria-label="free">
                <PriceIcon sx={{ mr: 1, fontSize: 20 }} />
                Free
              </ToggleButton>
              <ToggleButton value="dcFast" aria-label="dc fast">
                <DCFastIcon sx={{ mr: 1, fontSize: 20 }} />
               Fast
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <TextField
            fullWidth
            placeholder="Search stations..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setShowResults(true);
            }}
            onFocus={() => setShowResults(true)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          
          {showResults && searchQuery ? (
            <Box sx={{ overflow: 'auto', height: 'calc(100% - 60px)' }}>
              {(filteredLocations || []).map(location => (
                <ListItemButton
                  key={location.id}
                  onClick={() => handleLocationSelect(location.id)}
                >
                  <Typography>{location.name}</Typography>
                </ListItemButton>
              ))}
            </Box>
          ) : (
            <>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ overflow: 'auto', height: 'calc(100% - 60px)' }}>
                {locations?.data.map(station => (
                  <Card
                    key={station.id}
                    onClick={() => handleStationClick(station)}
                    sx={{ 
                      mb: 2,
                      cursor: 'pointer',
                      '&:hover': { bgcolor: 'background.paper' }
                    }}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
                        <Typography variant="h6">{station.name}</Typography>
                        {station.availableConnectors > 0 ? (
                          <Chip
                            label="Available"
                            color="success"
                            size="small"
                          />
                        ) : (
                          <Chip
                            label="In Use"
                            color="error"
                            size="small"
                          />
                        )}
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <AttachMoneyIcon fontSize="small" />
                          <Typography variant="body2">
                            {station?.currentPrice?.price ? 
                              `$${station.currentPrice.price.toFixed(2)}/kWh` : 
                              'Price unavailable'
                            }
                          </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <BoltIcon fontSize="small" />
                          <Typography variant="body2">
                            {typeof station.availableConnectors === 'number' && typeof station.totalConnectors === 'number' ? 
                              `${station.availableConnectors} / ${station.totalConnectors}` : 
                              'Status unavailable'
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </>
          )}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

const AppWrapper: React.FC = () => {
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  
  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ 
        bgcolor: 'background.default', 
        minHeight: '100vh',
        width: '100vw'
      }}>
        <Router>
          <Routes>
            <Route path="/" element={<App setSelectedLocation={setSelectedLocation} />} />
            <Route path="/locations/:id" element={<StationDetailWrapper />} />
            <Route path="/charging-session/:sessionId" element={<ChargingSessionWrapper />} />
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
};

const ChargingSessionWrapper = () => {
  const { sessionId } = useParams();
  return <ChargingSessionView sessionId={sessionId!} />;
};

export default AppWrapper; 